<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 h-6 w-6 sm:mr-6 mr-2" :badge="unreadNotifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-1 bg-primary text-white">
      </div>

      <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <notification-list
         v-if="displayNotifs && displayNotifs.length"
         :list="displayNotifs"
         />
      </VuePerfectScrollbar>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer"
        @click="$router.push('/notifications').catch(() => {})"
        >
        <span>View All Notifications</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>

import NotificationList from "./NotificationList.vue"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
    NotificationList
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    unreadNotifications() {
      return this.$store.state.notification.notifications.filter((notif) => (notif.is_seen == 0))
    },
    readNotifications() {
      return this.$store.state.notification.notifications.filter((notif) => (notif.is_seen == 1))
    },
    displayNotifs(){
      return (this.unreadNotifications.length > 1) ?
        [...this.unreadNotifications, ...this.readNotifications.slice(0,10)] :
        this.$store.state.notification.notifications.slice(0,10)
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    markAsRead(notifId){
      this.$store.dispatch("notification/markNotifAsRead", notifId)
    },
    actionExecutor(data){
      if(data.action == "fetch-order"){
        let order = this.$store.state.orderList.order;
        if(order && order.id == data.order_id){
          this.$store.dispatch("orderList/fetchOrder", data.order_id)
        }
      }
      if(data.action == "fetch-orders"){
        this.$store.dispatch("orderList/fetchOrders")
      }
    }
  },
  created(){
    this.$store.dispatch("notification/fetchNotifications")
  },
  mounted(){
     Echo.channel("buyer-notify-channel-"+this.activeUser.id)
        .listen('NotificationEvent', (e) => {
          this.$store.commit('notification/ADD_NOTIFICATION',e.data)
          this.actionExecutor(e.data)
      });
  }
}

</script>
